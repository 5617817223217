import React from 'react';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    label: {
        marginBottom: 16,
        fontSize: 17,
        fontFamily: 'Roboto',
        fontWeight: 400
    },
    input: {
        border: 'none',
        'border-bottom': '1px solid black',
        fontSize: 17,
        width: '100%',
        backgroundColor: 'transparent',
        outline: 'none'
    },
};

const InputWithFloatingLabel = ({ style, label, type, placeholder = '', value, setValue = (() => {}) }) => (
    <div style={{...styles.container, ...style}}>
        <p style={styles.label}>{label}</p>
        <input
            style={styles.input}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={event => setValue(event.target.value)}
        />
    </div>
);

export default InputWithFloatingLabel;