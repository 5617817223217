import React from 'react';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 17,
    fontFamily: 'Roboto',
    fontWeight: 4
  }
};

const TextWithIcon = ({ style, textStyle, iconStyle, icon, text, alt }) => (
    <div style={{...styles.container, ...style}}>
        <img style={iconStyle} src={icon} alt={alt} />
        <p style={{...styles.text, ...textStyle}}>{text}</p>
    </div>
);

export default TextWithIcon;
