import React, { useState, useContext } from 'react';
import { TranslationContext } from '../translation/translationContext';
import Screen from '../components/views/screen';
import Navbar from '../components/navbars/insideNavbar';
import { LIGHT_BLUE, PURPLE, WHITE } from '../assets/colors';
import { insideNavbarHeight } from '../assets/dimensions';
import StandardButton from '../components/buttons/standardButton';
import TextWithIcon from '../components/texts/textWithIcon';
import InputWithFloatingLabel from '../components/texts/inputWithFloatingLabel';
import time from '../assets/images/time.svg';
import location from '../assets/images/location.svg';
import emailIcon from '../assets/images/email.svg';
import { sendSupportMail } from '../services/mail';
import SentDialog from '../components/dialogs/simpleDialog';

const styles = {
  container: {
    backgroundColor: LIGHT_BLUE,
    paddingTop: insideNavbarHeight + 48,
    flexDirection: 'row',
    paddingBottom: 600
  },
  leftContent: {
    paddingTop: 40,
    paddingLeft: '15%',
    width: '40%'
  },
  rightContent: {
    backgroundColor: PURPLE,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '40%',
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 60
  },
  title: {
    color: PURPLE,
    fontSize: 40,
    fontWeight: 700,
    fontFamily: 'Roboto'
  },
  subTitle: {
    fontSize: 17,
    fontWeight: 400,
    fontFamily: 'Roboto'
  },
  infoTitle: {
    color: WHITE,
    fontSize: 40,
    fontWeight: 700,
    fontFamily: 'Roboto'
  },
  textWithIcon: {
    marginBottom: 40
  },
  iconText: {
    fontSize: 17,
    fontWeight: 400,
    fontFamily: 'Roboto',
    color: WHITE,
  },
  iconStyle: {
    width: 24,
    height: 24,
    marginRight: 16
  },
  sendButton: {
    width: '70%'
  },
  input: {
    width: '70%',
    marginBottom: 40
  }
};

const SupportPage = () => {
  const [subject, setSubject] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const { translate } = useContext(TranslationContext);

  const clear = () => {
    setSubject('');
    setEmail('');
    setMessage('');
  };

  const onSend = async () => {
    try {
      await sendSupportMail({ subject, email, message });
      setDialogOpen(true);
      clear();
    } catch (e) {
      // TODO: Show error
    }
  };

  return (
    <Screen style={styles.container}>
      <Navbar currentTab={'/support'} />
      <SentDialog
        message={translate('mailSent')}
        isOpen={dialogOpen}
        close={() => setDialogOpen(false)}
      />
      <div style={styles.leftContent}>
        <h1 style={styles.title}>{translate('supportTitle')}</h1>
        <h3 style={styles.subTitle}>{translate('supportDescription')}</h3>
        <InputWithFloatingLabel
            style={styles.input}
            label={translate('subject')}
            type='text'
            value={subject}
            setValue={setSubject}
        />
        <InputWithFloatingLabel
            style={styles.input}
            label={translate('email')}
            type='email'
            value={email}
            setValue={setEmail}
        />
        <InputWithFloatingLabel
            style={styles.input}
            label={translate('message')}
            type='text'
            value={message}
            setValue={setMessage}
        />
        <StandardButton style={styles.sendButton} text={translate('sendLarge')} onClick={onSend} />
      </div>
      <div style={styles.rightContent}>
        <h2 style={styles.infoTitle}>{translate('info')}</h2>
        <TextWithIcon
            style={styles.textWithIcon}
            textStyle={styles.iconText}
            iconStyle={styles.iconStyle}
            icon={emailIcon}
            text='support@chemactnetwork.com'
            alt='email'
        />
        <TextWithIcon
            style={styles.textWithIcon}
            textStyle={styles.iconText}
            iconStyle={styles.iconStyle}
            icon={location}
            text={translate('sweden')}
            alt='location'
        />
        <TextWithIcon
            style={styles.textWithIcon}
            textStyle={styles.iconText}
            iconStyle={styles.iconStyle}
            icon={time}
            text='09:00 - 17:00 MON-FRI (CET)'
            alt='time'
        />
      </div>
    </Screen>
  )
}

export default SupportPage;
